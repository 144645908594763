// src/redux/executorSkill/executorSkillSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchOrderedSkills,
    createExecutorSkill,
    updateExecutorSkill,
    deleteExecutorSkill,
    reorderExecutorSkills,
} from './executorSkillThunks';
import { ExecutorSkill } from './types'; // Import from types.ts

interface ExecutorSkillState {
    skills: ExecutorSkill[];
    loading: boolean;
    error: string | null;
}

const initialState: ExecutorSkillState = {
    skills: [],
    loading: false,
    error: null,
};

const executorSkillSlice = createSlice({
    name: 'executorSkill',
    initialState,
    reducers: {
        // Allows us to update the skills order locally for optimistic UI updates
        setSkillsOrder(state, action: PayloadAction<ExecutorSkill[]>) {
            state.skills = action.payload;
        },
        // Clear error action for handling errors
        clearError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch skills
            .addCase(fetchOrderedSkills.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderedSkills.fulfilled, (state, action: PayloadAction<ExecutorSkill[]>) => {
                state.loading = false;
                state.skills = action.payload;
            })
            .addCase(fetchOrderedSkills.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch skills';
            })
            // Create skill
            .addCase(createExecutorSkill.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createExecutorSkill.fulfilled, (state, action: PayloadAction<ExecutorSkill>) => {
                state.loading = false;
                state.skills.push(action.payload);
            })
            .addCase(createExecutorSkill.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to create skill';
            })
            // Update skill
            .addCase(updateExecutorSkill.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateExecutorSkill.fulfilled, (state, action: PayloadAction<ExecutorSkill>) => {
                state.loading = false;
                const index = state.skills.findIndex((skill) => skill.id === action.payload.id);
                if (index !== -1) state.skills[index] = action.payload;
            })
            .addCase(updateExecutorSkill.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update skill';
            })
            // Delete skill
            .addCase(deleteExecutorSkill.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteExecutorSkill.fulfilled, (state, action: PayloadAction<number>) => {
                state.loading = false;
                state.skills = state.skills.filter((skill) => skill.id !== action.payload);
            })
            .addCase(deleteExecutorSkill.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to delete skill';
            })
            // Reorder skills
            .addCase(reorderExecutorSkills.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reorderExecutorSkills.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(reorderExecutorSkills.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to reorder skills';
            });
    },
});

// Export the setSkillsOrder and clearError actions to be used in components
export const { setSkillsOrder, clearError } = executorSkillSlice.actions;
export default executorSkillSlice.reducer;
