import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, Select, MenuItem, Checkbox, List, ListItem, ListItemText, IconButton, Typography, InputAdornment } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { fetchUsers } from '../../redux/user/userThunks'; // Correct import
import { createNewTeam, updateExistingTeam, fetchTeamById } from '../../redux/team/teamThunks';
import { RootState, AppDispatch } from '../../redux/store';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { createArea, fetchOrderedAreas } from '../../redux/area/areaThunks';

const TeamForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { state } = useLocation(); // Get the state for the mode (edit, read, create)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [mode, setMode] = useState<'create' | 'edit' | 'read'>('create');
    const [teamName, setTeamName] = useState('');
    const [leader, setLeader] = useState<number | string>(''); // Adjust for number or string
    const [members, setMembers] = useState<number[]>([]); // Keep members as numbers
    const [area, setArea] = useState<number | string>(''); // Adjust for number or string
    const [newArea, setNewArea] = useState('');
    const [searchQuery, setSearchQuery] = useState(''); // State to track search input

    const users = useSelector((state: RootState) => state.users.users);
    const team = useSelector((state: RootState) => state.teams.selectedTeam);
    const areas = useSelector((state: RootState) => state.areas.areas);

    useEffect(() => {
        if (state?.mode) {
            setMode(state.mode);
        }
        if (id) {
            dispatch(fetchTeamById(Number(id)));
        }
        dispatch(fetchUsers()); // Fetch the users
        dispatch(fetchOrderedAreas());  // Fetch areas when the component is loaded
    }, [dispatch, id, state?.mode]);

    useEffect(() => {
        if (team && mode !== 'create') {
            setTeamName(team.name);
            setLeader(team.leader?.id || '');
            setMembers(team.members?.map((member: any) => member.id) || []);
            setArea(team.area?.id || '');
        }
    }, [team, mode]);

    const handleSave = async () => {
        const teamData = {
            name: teamName,
            leaderId: Number(leader), // Convert leader to number
            members, // Members are already numbers
            areaId: Number(area), // Convert area to number
        };

        if (mode === 'create') {
            await dispatch(createNewTeam(teamData));
        } else if (mode === 'edit') {
            await dispatch(updateExistingTeam({ id: Number(id), data: teamData }));
        }
        navigate('/teams');
    };

    const handleCancel = () => {
        navigate('/teams');
    };

    const handleNewAreaSave = () => {
        if (newArea.trim()) {
            dispatch(createArea({ name: newArea.trim() }));
            setNewArea('');
        }
    };

    const handleMemberToggle = (memberId: number) => {
        setMembers((prev) =>
            prev.includes(memberId)
                ? prev.filter((id) => id !== memberId)
                : [...prev, memberId]
        );
    };

    const removeMember = (memberId: number) => {
        setMembers((prev) => prev.filter((id) => id !== memberId));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const clearSearch = () => {
        setSearchQuery('');
    };

    // Filter users based on search query
    const filteredUsers = users.filter(
        user => user.role === 'EXECUTOR_ADMINISTRATOR' &&
            user.jobTitle !== 'Бригадир' &&
            user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) // Search filter
    );

    return (
        <div>
            <Typography variant="h4">
                {mode === 'create' ? 'Creating a new team' : mode === 'edit' ? `Changing team data ${teamName}` : `Viewing information about the team ${teamName}`}
            </Typography>

            <TextField
                label="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                disabled={mode === 'read'}
                fullWidth
            />

            <Select
                label="Leader"
                value={leader}
                onChange={(e) => setLeader(e.target.value)}
                disabled={mode === 'read'}
                fullWidth
            >
                {users.filter(user => user.role === 'EXECUTOR_ADMINISTRATOR' && user.jobTitle === 'Бригадир').map(user => (
                    <MenuItem key={user.id} value={String(user.id)}> {/* Convert number to string */}
                        {user.fullName}
                    </MenuItem>
                ))}
            </Select>

            <Typography variant="h6">Team Members</Typography>

            {/* Selected members */}
            <div>
                {members.map((memberId) => {
                    const member = users.find((user) => user.id === memberId);
                    return (
                        <span key={memberId}>
                            {member?.fullName}
                            <IconButton onClick={() => removeMember(memberId)} size="small">
                                <CloseIcon />
                            </IconButton>
                        </span>
                    );
                })}
            </div>

            {/* Search input for members */}
            {mode !== 'read' && (
                <TextField
                    label="Search Members"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchQuery && (
                                    <IconButton onClick={clearSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            )}


            {/* Filtered member list */}
            <List>
                {filteredUsers.map(user => (
                    <ListItem key={user.id}>
                        <Checkbox
                            checked={members.includes(user.id)}
                            onChange={() => handleMemberToggle(user.id)}
                            disabled={mode === 'read'}
                        />
                        <ListItemText primary={user.fullName} />
                    </ListItem>
                ))}
            </List>

            <Typography variant="h6">Area</Typography>
            <Select
                label="Area"
                value={String(area)} // Convert area ID to string for Select
                onChange={(e) => setArea(e.target.value)}
                disabled={mode === 'read'}
                fullWidth
            >
                {areas.map(area => (
                    <MenuItem key={area.id} value={String(area.id)}> {/* Convert number to string */}
                        {area.name}
                    </MenuItem>
                ))}
            </Select>

            {mode !== 'read' && (
                <>
                    <TextField
                        label="Create New Area"
                        value={newArea}
                        onChange={(e) => setNewArea(e.target.value)}
                        fullWidth
                    />
                    <Button onClick={handleNewAreaSave} variant="contained">Save Area</Button>
                </>
            )}

            <div>
                {mode === 'read' ? (
                    <Button onClick={handleCancel} variant="contained">Return back</Button>
                ) : (
                    <>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                        <Button onClick={handleCancel} variant="outlined">Cancel</Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default TeamForm;
