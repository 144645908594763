import React from 'react';
import { Table, TableRow, TableCell, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export interface Resolution {
    id: number;
    name: string;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
    requiresNote: Boolean; // Ensure this property exists here
}

interface ResolutionTableProps {
    title: string;
    color: string;
    resolutions: Resolution[]; // Use the Resolution type
    onRowClick: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (id: number, name: string) => void;
}

const ResolutionTable: React.FC<ResolutionTableProps> = ({ title, color, resolutions, onRowClick, onEdit, onDelete }) => (
    <div>
        <Typography variant="h5" sx={{ color, fontWeight: 'bold' }}>{title}</Typography>
        <Table>
            <TableRow>
                <TableCell><strong>Resolution Name</strong></TableCell>
                <TableCell><strong>Resolution Note</strong></TableCell>
                <TableCell><strong>Options</strong></TableCell>
            </TableRow>
            {resolutions.map((res) => (
                <TableRow key={res.id} onClick={() => onRowClick(res.id)} style={{ cursor: 'pointer' }}>
                    <TableCell>{res.name}</TableCell>
                    <TableCell>{res.requiresNote ? 'Note is optional' : 'No note'}</TableCell>
                    <TableCell>
                        <IconButton onClick={(e) => { e.stopPropagation(); onEdit(res.id); }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={(e) => { e.stopPropagation(); onDelete(res.id, res.name); }}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    </div>
);

export default ResolutionTable;
