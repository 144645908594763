// src/redux/executorSkill/executorSkillThunks.ts
import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    createExecutorSkillApi,
    deleteExecutorSkillApi,
    fetchExecutorSkills,
    reorderExecutorSkillsApi,
    updateExecutorSkillApi
} from '../../api/executorSkillApi';
import {ExecutorSkill} from './types'; // Import from types.ts

export const fetchOrderedSkills = createAsyncThunk<ExecutorSkill[]>(
    'executorSkill/fetchOrderedSkills',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchExecutorSkills();
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch ordered skills');
        }
    }
);

export const createExecutorSkill = createAsyncThunk<ExecutorSkill, { name: string }>(
    'executorSkill/createExecutorSkill',
    async (skill, { rejectWithValue }) => {
        try {
            return await createExecutorSkillApi(skill); // Send only the name
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to create skill');
        }
    }
);

export const reorderExecutorSkills = createAsyncThunk<void, number[]>(
    'executorSkill/reorderExecutorSkills',
    async (orderedIds, { rejectWithValue }) => {
        try {
            await reorderExecutorSkillsApi(orderedIds);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to reorder skills');
        }
    }
);

export const updateExecutorSkill = createAsyncThunk<ExecutorSkill, { id: number; skill: Partial<ExecutorSkill> }>(
    'executorSkill/updateExecutorSkill',
    async ({ id, skill }, { rejectWithValue }) => {
        try {
            return await updateExecutorSkillApi(id, skill);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to update skill');
        }
    }
);


export const deleteExecutorSkill = createAsyncThunk<number, number>(
    'executorSkill/deleteExecutorSkill',
    async (id, { rejectWithValue }) => {
        try {
            await deleteExecutorSkillApi(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to delete skill');
        }
    }
);
