import {createSlice} from '@reduxjs/toolkit';
import {fetchUsers, fetchUserById, createNewUser, updateExistingUser, deleteUserById} from './userThunks';

export interface User {
    id: number;
    email: string;
    role: string;
    fullName: string;
    jobTitle?: string;
    mobilePhone?: string;
    timeZone?: string;
}

interface UserState {
    users: User[];
    selectedUser: User | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UserState = {
    users: [],
    selectedUser: null,
    status: 'idle',
    error: null,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch users';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.selectedUser = action.payload;
            })
            .addCase(createNewUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(updateExistingUser.fulfilled, (state, action) => {
                const index = state.users.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
            })
            .addCase(deleteUserById.fulfilled, (state, action) => {
                state.users = state.users.filter((user) => user.id !== action.meta.arg);
            });
    },
});

export default userSlice.reducer;
