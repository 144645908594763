import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    IconButton,
    CircularProgress,
    Button,
    Modal,
    Box,
    TableCell, TableRow, TableHead, Table, TableContainer, TableBody,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {
    fetchTemplatesThunk,
    deleteTemplateThunk,
} from '../../redux/templates/templateThunks';
import { fetchResolutionsByTemplateThunk } from '../../redux/resolutions/resolutionsThunks';
import { AppDispatch, RootState } from '../../redux/store';
import {fetchReportFieldsThunk} from '../../redux/reports/reportsThunks';

const TemplateList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { templates, status, error } = useSelector(
        (state: RootState) => state.template
    );
    const [selectedTemplateId, setSelectedTemplateId] = React.useState<number | null>(
        null
    );
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

    useEffect(() => {
        dispatch(fetchTemplatesThunk());
    }, [dispatch]);

    const handleAddTemplate = () => {
        navigate("/templates/new");
    };

    const handleEdit = async (id: number) => {
        setSelectedTemplateId(id);

        try {
            // Fetch related entities for the template
            await dispatch(fetchReportFieldsThunk(id));
            await dispatch(fetchResolutionsByTemplateThunk(id));

            // Navigate to the template editing form
            navigate(`/templates/edit/${id}`);
        } catch (error) {
            console.error("Error fetching related template data", error);
        }
    };

    const handleDeleteModalOpen = (id: number) => {
        setSelectedTemplateId(id);
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
        setSelectedTemplateId(null);
    };

    const handleDelete = async () => {
        if (selectedTemplateId !== null) {
            await dispatch(deleteTemplateThunk(selectedTemplateId));
            handleDeleteModalClose();
        }
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Шаблоны
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddTemplate}
                style={{ marginBottom: "20px" }}
            >
                + Добавить шаблон
            </Button>

            {status === "loading" ? (
                <CircularProgress />
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="h6">Название шаблона</Typography></TableCell>
                                <TableCell><Typography variant="h6">Опции</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template) => (
                                <TableRow key={template.id}>
                                    {/* Template Name Column */}
                                    <TableCell>
                                        <Typography>{template.name}</Typography>
                                    </TableCell>

                                    {/* Options Column */}
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(template.id)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteModalOpen(template.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}


            {/* Modal for confirming template deletion */}
            <Modal
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        padding: "20px",
                        margin: "auto",
                        marginTop: "20vh",
                        width: "400px",
                        borderRadius: "10px",
                        textAlign: "center",
                    }}
                >
                    <Typography id="delete-modal-title" variant="h6" gutterBottom>
                        Вы уверены, что хотите удалить шаблон?
                    </Typography>
                    <Typography id="delete-modal-description" gutterBottom>
                        Шаблон и все связанные данные будут удалены безвозвратно.
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="contained" onClick={handleDeleteModalClose}>
                            Отмена
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            Удалить
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default TemplateList;
