// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import authReducer from './auth/authSlice';
import workOrderReducer from './workOrder/workOrderSlice';
import teamReducer from './team/teamSlice';
import areaReducer from './area/areaSlice';
import resolutionReducer from './resolutions/resolutionsSlice';
import executorSkillReducer from './executorSkill/executorSkillSlice';
import orderTypeReducer from './orderType/orderTypeSlice';
import valueListReducer from './valueList/valueListSlice';
import templateReducer from './templates/templateSlice';
import reportReducer from './reports/reportsSlice'
import clientsReducer from './clients/clientsSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,             // Handles authentication state
        workOrder: workOrderReducer,    // Handles work orders state
        users: userReducer,
        teams: teamReducer,
        areas: areaReducer,
        resolutions: resolutionReducer,
        executorSkill: executorSkillReducer,
        orderType: orderTypeReducer,
        valueList: valueListReducer,
        template: templateReducer,
        report: reportReducer,
        clients: clientsReducer,
        // Add other reducers here as needed
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };
