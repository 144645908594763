import React from 'react';
import AreaList from '../components/Area/AreaList';

const Areas = () => {
    return (
        <>
            <AreaList/>
        </>
    );
};

export default Areas;