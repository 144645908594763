import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchReportFieldsThunk,
    createReportFieldThunk,
    updateReportFieldThunk,
    deleteReportFieldThunk,
} from './reportsThunks';

interface ReportField {
    id: number;
    templateId: number;
    name: string;
    fieldType: string; // Replace with enum if needed
    required?: boolean;
    valueListId?: number;
}

interface ReportsState {
    reportFields: ReportField[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ReportsState = {
    reportFields: [],
    status: 'idle',
    error: null,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportFieldsThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReportFieldsThunk.fulfilled, (state, action: PayloadAction<ReportField[]>) => {
                state.status = 'succeeded';
                state.reportFields = action.payload;
                state.error = null;
            })
            .addCase(fetchReportFieldsThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch report fields';
            })
            .addCase(createReportFieldThunk.fulfilled, (state, action: PayloadAction<ReportField>) => {
                state.reportFields.unshift(action.payload); // Add new field to the start
                state.error = null;
            })
            .addCase(updateReportFieldThunk.fulfilled, (state, action: PayloadAction<ReportField>) => {
                const index = state.reportFields.findIndex(field => field.id === action.payload.id);
                if (index !== -1) state.reportFields[index] = action.payload;
                state.error = null;
            })
            .addCase(deleteReportFieldThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.reportFields = state.reportFields.filter(field => field.id !== action.payload);
                state.error = null;
            });
    },
});

export default reportsSlice.reducer;
