import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchReportFieldsByTemplate,
    createReportField,
    updateReportField,
    deleteReportField, ReportField,
} from '../../api/reportFieldsApi';


// Fetch all report fields by template
export const fetchReportFieldsThunk = createAsyncThunk(
    'reports/fetchAll',
    async (templateId: number, { rejectWithValue }) => {
        try {
            return await fetchReportFieldsByTemplate(templateId);
        } catch (error) {
            return rejectWithValue('Failed to fetch report fields');
        }
    }
);

// Create a new report field
export const createReportFieldThunk = createAsyncThunk(
    'reports/create',
    async (reportField: { templateId: number; name: string; fieldType: string; required?: boolean; valueListId?: number, link: string }, { rejectWithValue }) => {
        try {
            return await createReportField(reportField);
        } catch (error) {
            return rejectWithValue('Failed to create report field');
        }
    }
);

// Update a report field
export const updateReportFieldThunk = createAsyncThunk(
    'reports/update',
    async ({ id, reportField }: { id: number; reportField: Partial<ReportField> }, { rejectWithValue }) => {
        try {
            return await updateReportField(id, reportField);
        } catch (error) {
            return rejectWithValue('Failed to update report field');
        }
    }
);

// Delete a report field
export const deleteReportFieldThunk = createAsyncThunk(
    'reports/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteReportField(id);
            return id;
        } catch (error) {
            return rejectWithValue('Failed to delete report field');
        }
    }
);
