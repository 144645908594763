import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchValueListsApi,
    fetchValueListByIdApi,
    createValueListApi,
    updateValueListApi,
    deleteValueListApi,
    reorderValueListOptionsApi,
} from '../../api/valueListApi';
import { ValueList } from './types';

// Fetch all value lists
export const fetchValueLists = createAsyncThunk<ValueList[]>(
    'valueList/fetchValueLists',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchValueListsApi();
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch value lists');
        }
    }
);

// Fetch a single value list by ID
export const fetchValueListById = createAsyncThunk<ValueList, number>(
    'valueList/fetchValueListById',
    async (id, { rejectWithValue }) => {
        try {
            return await fetchValueListByIdApi(id);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch value list');
        }
    }
);

// Create a new value list
export const createValueList = createAsyncThunk<ValueList, { name: string; options: string[] }>(
    'valueList/createValueList',
    async (data, { rejectWithValue }) => {
        try {
            return await createValueListApi(data);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to create value list');
        }
    }
);

// Update an existing value list
export const updateValueList = createAsyncThunk<ValueList, { id: number; data: Partial<{ name: string; options: string[] }> }>(
    'valueList/updateValueList',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await updateValueListApi(id, data);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to update value list');
        }
    }
);

// Delete a value list
export const deleteValueList = createAsyncThunk<number, number>(
    'valueList/deleteValueList',
    async (id, { rejectWithValue }) => {
        try {
            await deleteValueListApi(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to delete value list');
        }
    }
);

// Reorder options in a value list
export const reorderValueListOptions = createAsyncThunk<ValueList, { id: number; orderedOptions: string[] }>(
    'valueList/reorderValueListOptions',
    async ({ id, orderedOptions }, { rejectWithValue }) => {
        try {
            return await reorderValueListOptionsApi(id, orderedOptions);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to reorder options');
        }
    }
);
