import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchResolutions,
    fetchResolutionById,
    createResolution,
    updateResolution,
    deleteResolution,
    fetchResolutionsByTemplate,
    linkResolutionsToTemplate,
    unlinkResolutionsFromTemplate, ResolutionInput, Resolution,
} from '../../api/resolutionsApi';

export const fetchResolutionsThunk = createAsyncThunk(
    'resolutions/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchResolutions();
        } catch (error) {
            return rejectWithValue('Failed to fetch resolutions');
        }
    }
);

// Fetch resolutions by template
export const fetchResolutionsByTemplateThunk = createAsyncThunk(
    'resolutions/fetchByTemplate',
    async (templateId: number, { rejectWithValue }) => {
        try {
            return await fetchResolutionsByTemplate(templateId);
        } catch (error) {
            return rejectWithValue(`Failed to fetch resolutions for template ID ${templateId}`);
        }
    }
);

export const fetchResolutionByIdThunk = createAsyncThunk(
    'resolutions/fetchById',
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchResolutionById(id);
        } catch (error) {
            return rejectWithValue(`Failed to fetch resolution with ID ${id}`);
        }
    }
);

export const createResolutionThunk = createAsyncThunk(
    'resolutions/create',
    async (data: ResolutionInput, { rejectWithValue }) => {
        try {
            const createdResolution: Resolution = await createResolution(data);
            return createdResolution; // Return the complete resolution object (with id)
        } catch (error) {
            return rejectWithValue('Failed to create resolution');
        }
    }
);

export const updateResolutionThunk = createAsyncThunk(
    'resolutions/update',
    async (
        { id, data }: { id: number; data: ResolutionInput },
        { rejectWithValue }
    ) => {
        try {
            const updatedResolution: Resolution = await updateResolution(id, data);
            return updatedResolution; // Return the complete resolution object (with id)
        } catch (error) {
            return rejectWithValue(`Failed to update resolution with ID ${id}`);
        }
    }
);


export const deleteResolutionThunk = createAsyncThunk(
    'resolutions/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteResolution(id);
            return id;
        } catch (error) {
            return rejectWithValue(`Failed to delete resolution with ID ${id}`);
        }
    }
);

export const linkResolutionsToTemplateThunk = createAsyncThunk(
    'resolutions/linkToTemplate',
    async ({ templateId, resolutionIds }: { templateId: number; resolutionIds: number[] }, { rejectWithValue }) => {
        try {
            await linkResolutionsToTemplate(templateId, resolutionIds);
            const resolutions = await fetchResolutionsByTemplate(templateId);
            return resolutions; // Resolutions now have the required 'id' field
        } catch (error) {
            return rejectWithValue(`Failed to link resolutions to template ID ${templateId}`);
        }
    }
);


export const unlinkResolutionsFromTemplateThunk = createAsyncThunk(
    'resolutions/unlinkFromTemplate',
    async ({ templateId, resolutionIds }: { templateId: number; resolutionIds: number[] }, { rejectWithValue }) => {
        try {
            await unlinkResolutionsFromTemplate(templateId, resolutionIds);
            return resolutionIds; // Return the removed resolution IDs
        } catch (error) {
            return rejectWithValue(`Failed to unlink resolutions from template ID ${templateId}`);
        }
    }
);

