import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchClientsThunk,
    searchClientsThunk,
    fetchClientByIdThunk,
    createClientThunk,
    updateClientThunk,
    deleteClientThunk,
    reverseGeocodeThunk,
    geocodeAddressThunk,
} from './clientsThunks';
import { Client } from './types';

interface ClientsState {
    clients: Client[];
    selectedClient: Client | null;
    loading: boolean;
    error: string | null;
    searchResults: Client[]; // For handling client search results
    geocodeResult: { latitude: number; longitude: number } | null; // Result from geocode API
    reverseGeocodeResult: string | null; // Address result from reverse geocoding
}

const initialState: ClientsState = {
    clients: [],
    selectedClient: null,
    loading: false,
    error: null,
    searchResults: [],
    geocodeResult: null,
    reverseGeocodeResult: null,
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        clearSelectedClient(state) {
            state.selectedClient = null;
        },
        clearSearchResults(state) {
            state.searchResults = [];
        },
        clearGeocodeResult(state) {
            state.geocodeResult = null;
        },
        clearReverseGeocodeResult(state) {
            state.reverseGeocodeResult = null;
        },
    },
    extraReducers: (builder) => {
        // Fetch all clients
        builder
            .addCase(fetchClientsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClientsThunk.fulfilled, (state, action: PayloadAction<Client[]>) => {
                state.clients = action.payload;
                state.loading = false;
            })
            .addCase(fetchClientsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to fetch clients.';
            });

        // Search clients by name or phone
        builder
            .addCase(searchClientsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchClientsThunk.fulfilled, (state, action: PayloadAction<Client[]>) => {
                state.searchResults = action.payload;
                state.loading = false;
            })
            .addCase(searchClientsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to search clients.';
            });

        // Fetch client by ID
        builder
            .addCase(fetchClientByIdThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClientByIdThunk.fulfilled, (state, action: PayloadAction<Client>) => {
                state.selectedClient = action.payload;
                state.loading = false;
            })
            .addCase(fetchClientByIdThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to fetch client by ID.';
            });

        // Create a new client
        builder
            .addCase(createClientThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createClientThunk.fulfilled, (state, action: PayloadAction<Client>) => {
                state.clients.push(action.payload);
                state.loading = false;
            })
            .addCase(createClientThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to create client.';
            });

        // Update an existing client
        builder
            .addCase(updateClientThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateClientThunk.fulfilled, (state, action: PayloadAction<Client>) => {
                const index = state.clients.findIndex((client) => client.id === action.payload.id);
                if (index !== -1) {
                    state.clients[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(updateClientThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to update client.';
            });

        // Delete a client
        builder
            .addCase(deleteClientThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteClientThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.clients = state.clients.filter((client) => client.id !== action.payload);
                state.loading = false;
            })
            .addCase(deleteClientThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to delete client.';
            });

        // Geocode address
        builder
            .addCase(geocodeAddressThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(geocodeAddressThunk.fulfilled, (state, action: PayloadAction<{ latitude: number; longitude: number }>) => {
                state.geocodeResult = action.payload;
                state.loading = false;
            })
            .addCase(geocodeAddressThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to geocode address.';
            });

        // Reverse geocode coordinates
        builder
            .addCase(reverseGeocodeThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reverseGeocodeThunk.fulfilled, (state, action: PayloadAction<string>) => {
                state.reverseGeocodeResult = action.payload;
                state.loading = false;
            })
            .addCase(reverseGeocodeThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || action.error.message || 'Failed to reverse geocode coordinates.';
            });
    },
});

export const {
    clearSelectedClient,
    clearSearchResults,
    clearGeocodeResult,
    clearReverseGeocodeResult,
} = clientsSlice.actions;

export default clientsSlice.reducer;
