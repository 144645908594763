import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchResolutionsThunk, deleteResolutionThunk } from '../../redux/resolutions/resolutionsThunks';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography, Box } from '@mui/material';
import ResolutionTable from './ResolutionTable';

export interface Resolution {
    id: number;
    name: string;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
    noteRequired: boolean; // Ensure this property exists here
}

const ResolutionsList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
    const [openModal, setOpenModal] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [deleteName, setDeleteName] = useState<string>('');

    useEffect(() => {
        dispatch(fetchResolutionsThunk());
    }, [dispatch]);

    const handleAddResolution = () => {
        navigate('/resolutions/new', { state: { mode: 'create' } });
    };

    const handleEditResolution = (id: number) => {
        navigate(`/resolutions/${id}`, { state: { mode: 'edit' } });
    };

    const handleRowClick = (id: number) => {
        navigate(`/resolutions/${id}`, { state: { mode: 'read' } });
    };

    const handleDeleteResolution = (id: number, name: string) => {
        setDeleteId(id);
        setDeleteName(name);
        setOpenModal(true);
    };

    const confirmDeleteResolution = () => {
        if (deleteId !== null) {
            dispatch(deleteResolutionThunk(deleteId));
            setOpenModal(false);
        }
    };

    const successfulResolutions = resolutions.filter(res => res.type === 'SUCCESSFUL');
    const unsuccessfulResolutions = resolutions.filter(res => res.type === 'UNSUCCESSFUL');

    return (
        <div>
            <Typography variant="h4">Resolutions</Typography>
            <Button variant="contained" color="primary" onClick={handleAddResolution} sx={{ margin: '10px 0' }}>
                Add Resolution
            </Button>

            <ResolutionTable
                title="Successful Resolutions"
                color="green"
                resolutions={successfulResolutions}
                onRowClick={handleRowClick}
                onEdit={handleEditResolution}
                onDelete={handleDeleteResolution}
            />

            <ResolutionTable
                title="Unsuccessful Resolutions"
                color="red"
                resolutions={unsuccessfulResolutions}
                onRowClick={handleRowClick}
                onEdit={handleEditResolution}
                onDelete={handleDeleteResolution}
            />

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ padding: 2, backgroundColor: 'white', borderRadius: '8px', width: '400px', margin: 'auto' }}>
                    <Typography variant="h6">Are you sure you want to delete the resolution {deleteName}?</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" onClick={() => setOpenModal(false)}>Cancel</Button>
                        <Button variant="contained" color="secondary" onClick={confirmDeleteResolution}>Yes, delete</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ResolutionsList;
