import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValueList } from './types';
import { fetchValueLists, createValueList, updateValueList, deleteValueList, reorderValueListOptions, fetchValueListById } from './valueListThunks';

interface ValueListState {
    valueLists: ValueList[]; // List of all ValueLists
    activeValueList: ValueList | null; // Currently selected ValueList for detailed view or editing
    loading: boolean; // Loading status for any operations
    error: string | null; // Error message, if any
}

const initialState: ValueListState = {
    valueLists: [],
    activeValueList: null,
    loading: false,
    error: null,
};

const valueListSlice = createSlice({
    name: 'valueList',
    initialState,
    reducers: {
        clearActiveValueList(state) {
            state.activeValueList = null; // Clear active ValueList when not needed
        },
        clearError(state) {
            state.error = null; // Clear error messages
        },
    },
    extraReducers: (builder) => {
        // Fetch all ValueLists
        builder.addCase(fetchValueLists.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchValueLists.fulfilled, (state, action: PayloadAction<ValueList[]>) => {
            state.loading = false;
            state.valueLists = action.payload;
        });
        builder.addCase(fetchValueLists.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to fetch value lists';
        });

        // Fetch ValueList by ID
        builder.addCase(fetchValueListById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchValueListById.fulfilled, (state, action: PayloadAction<ValueList>) => {
            state.loading = false;
            state.activeValueList = action.payload;
        });
        builder.addCase(fetchValueListById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to fetch value list';
        });

        // Create a new ValueList
        builder.addCase(createValueList.fulfilled, (state, action: PayloadAction<ValueList>) => {
            state.valueLists.push(action.payload);
        });

        // Update an existing ValueList
        builder.addCase(updateValueList.fulfilled, (state, action: PayloadAction<ValueList>) => {
            const index = state.valueLists.findIndex((list) => list.id === action.payload.id);
            if (index !== -1) {
                state.valueLists[index] = action.payload;
            }

            // Update activeValueList if it matches the updated one
            if (state.activeValueList?.id === action.payload.id) {
                state.activeValueList = action.payload;
            }
        });

        // Delete a ValueList
        builder.addCase(deleteValueList.fulfilled, (state, action: PayloadAction<number>) => {
            state.valueLists = state.valueLists.filter((list) => list.id !== action.payload);

            // Clear activeValueList if it was the deleted one
            if (state.activeValueList?.id === action.payload) {
                state.activeValueList = null;
            }
        });

        // Reorder options in a ValueList
        builder.addCase(reorderValueListOptions.fulfilled, (state, action: PayloadAction<ValueList>) => {
            const index = state.valueLists.findIndex((list) => list.id === action.payload.id);
            if (index !== -1) {
                state.valueLists[index] = action.payload;
            }

            // Update activeValueList if it matches the reordered one
            if (state.activeValueList?.id === action.payload.id) {
                state.activeValueList = action.payload;
            }
        });
    },
});

// Export actions for external use
export const { clearActiveValueList, clearError } = valueListSlice.actions;

export default valueListSlice.reducer;
