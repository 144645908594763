import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Checkbox, FormControlLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './LoginForm.scss';
import { useAuth } from '../../hooks/useAuth';

const validationSchema = Yup.object({
    email: Yup.string().required('This field must be filled in.'),
    password: Yup.string().required('This field must be filled in.'),
});

const LoginForm: React.FC = () => {
    const { login } = useAuth();

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const formik = useFormik({
        initialValues: { email: '', password: '', rememberMe: false },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await login(values.email, values.password, values.rememberMe);
                setError(null);
            } catch (error) {
                // Handle the error based on its type
                if (error instanceof Error) {
                    setError(error.message); // assuming error.message is the desired string
                } else if (typeof error === 'string') {
                    setError(error); // in case the error is already a string
                } else {
                    setError('An unknown error occurred'); // fallback for unexpected types
                }
            }
        },
    });

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <form onSubmit={formik.handleSubmit} className="login-form">
            <div className="text-field">
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </div>
            <div className="text-field">
                <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'} // Toggle between text and password types
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                        // Add the visibility toggle button as an adornment
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <FormControlLabel
                control={<Checkbox name="rememberMe" checked={formik.values.rememberMe} onChange={formik.handleChange} />}
                label="Remember me"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button color="primary" variant="contained" fullWidth type="submit">
                Login
            </Button>
        </form>
    );
};

export default LoginForm;
