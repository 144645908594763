import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOrderedOrderTypes, createOrderType, updateOrderType, deleteOrderType, reorderOrderTypes } from './orderTypeThunks';
import { OrderType } from './types';

interface OrderTypeState {
    orderTypes: OrderType[];
    loading: boolean;
    error: string | null;
}

const initialState: OrderTypeState = {
    orderTypes: [],
    loading: false,
    error: null,
};

const orderTypeSlice = createSlice({
    name: 'orderType',
    initialState,
    reducers: {
        setOrderTypesOrder(state, action: PayloadAction<OrderType[]>) {
            state.orderTypes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderedOrderTypes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderedOrderTypes.fulfilled, (state, action: PayloadAction<OrderType[]>) => {
                state.loading = false;
                state.orderTypes = action.payload;
            })
            .addCase(fetchOrderedOrderTypes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(createOrderType.fulfilled, (state, action: PayloadAction<OrderType>) => {
                state.orderTypes.push(action.payload);
            })
            .addCase(updateOrderType.fulfilled, (state, action: PayloadAction<OrderType>) => {
                const index = state.orderTypes.findIndex((type) => type.id === action.payload.id);
                if (index !== -1) state.orderTypes[index] = action.payload;
            })
            .addCase(deleteOrderType.fulfilled, (state, action: PayloadAction<number>) => {
                state.orderTypes = state.orderTypes.filter((type) => type.id !== action.payload);
            });
    },
});

export const { setOrderTypesOrder } = orderTypeSlice.actions;
export default orderTypeSlice.reducer;
