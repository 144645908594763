import axiosInstance from "./axiosConfig";
import { Template } from "../redux/templates/types";

// Fetch all templates
export const fetchTemplatesApi = async (): Promise<Template[]> => {
    const response = await axiosInstance.get("/templates");
    return response.data;
};

// Fetch a single template by ID
export const fetchTemplateById = async (id: number): Promise<Template> => {
    const response = await axiosInstance.get(`/templates/${id}`);
    return response.data;
};

// Create a new template
export const createTemplate = async (
    template: Omit<Template, "id">
): Promise<Template> => {
    const response = await axiosInstance.post("/templates", template);
    return response.data;
};

// Update an existing template by ID
export const updateTemplate = async (
    id: number,
    template: Partial<Omit<Template, "id">>
): Promise<Template> => {
    const response = await axiosInstance.patch(`/templates/${id}`, template);
    return response.data;
};

// Delete a template by ID
export const deleteTemplate = async (id: number): Promise<void> => {
    await axiosInstance.delete(`/templates/${id}`);
};
