import axiosInstance from './axiosConfig';
import { OrderType } from '../redux/orderType/types';

export const fetchOrderTypes = async () => {
    const response = await axiosInstance.get<OrderType[]>('/order-type');
    return response.data;
};

export const reorderOrderTypesApi = async (orderedIds: number[]) => {
    const response = await axiosInstance.patch('/order-type/reorder', { orderedIds });
    return response.data;
};

export const createOrderTypeApi = async (orderType: { name: string }) => {
    const response = await axiosInstance.post('/order-type', { name: orderType.name });
    return response.data;
};

export const updateOrderTypeApi = async (id: number, orderType: Partial<OrderType>) => {
    const response = await axiosInstance.patch(`/order-type/${id}`, orderType);
    return response.data;
};

export const deleteOrderTypeApi = async (id: number) => {
    const response = await axiosInstance.delete(`/order-type/${id}`);
    return response.data;
};
