import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchOrderTypes,
    createOrderTypeApi,
    updateOrderTypeApi,
    deleteOrderTypeApi,
    reorderOrderTypesApi,
} from '../../api/orderTypeApi';
import { OrderType } from './types';

export const fetchOrderedOrderTypes = createAsyncThunk<OrderType[]>(
    'orderType/fetchOrderedOrderTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchOrderTypes();
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch order types');
        }
    }
);

export const createOrderType = createAsyncThunk<OrderType, { name: string }>(
    'orderType/createOrderType',
    async (orderType, { rejectWithValue }) => {
        try {
            return await createOrderTypeApi(orderType);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to create order type');
        }
    }
);

export const reorderOrderTypes = createAsyncThunk<void, number[]>(
    'orderType/reorderOrderTypes',
    async (orderedIds, { rejectWithValue }) => {
        try {
            await reorderOrderTypesApi(orderedIds);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to reorder order types');
        }
    }
);

export const updateOrderType = createAsyncThunk<OrderType, { id: number; orderType: Partial<OrderType> }>(
    'orderType/updateOrderType',
    async ({ id, orderType }, { rejectWithValue }) => {
        try {
            return await updateOrderTypeApi(id, orderType);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to update order type');
        }
    }
);

export const deleteOrderType = createAsyncThunk<number, number>(
    'orderType/deleteOrderType',
    async (id, { rejectWithValue }) => {
        try {
            await deleteOrderTypeApi(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to delete order type');
        }
    }
);
