import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchOrderedOrderTypes, createOrderType, updateOrderType, deleteOrderType, reorderOrderTypes } from '../../redux/orderType/orderTypeThunks';
import { OrderType } from '../../redux/orderType/types';
import { setOrderTypesOrder } from '../../redux/orderType/orderTypeSlice';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Button, TextField, Typography, List, ListItem, ListItemText, IconButton, CircularProgress } from '@mui/material';
import { Edit, Delete, Save, DragIndicator } from '@mui/icons-material';

const ITEM_TYPE = 'OrderType';

interface OrderTypeItemProps {
    orderType: OrderType;
    index: number;
    moveOrderType: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (orderType: OrderType) => void;
}

const OrderTypeItem: React.FC<OrderTypeItemProps> = ({ orderType, index, moveOrderType, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveOrderType(item.index, index);
                item.index = index;
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(orderType.name);

    const handleSave = () => {
        onSave({ ...orderType, name: editedName });
        setIsEditing(false);
    };

    return (
        <ListItem
            ref={(node) => dragRef(dropRef(node))}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                borderBottom: '1px solid #ddd',
            }}
            secondaryAction={
                <>
                    {isEditing ? (
                        <IconButton edge="end" onClick={handleSave}>
                            <Save />
                        </IconButton>
                    ) : (
                        <IconButton edge="end" onClick={() => setIsEditing(true)}>
                            <Edit />
                        </IconButton>
                    )}
                    <IconButton edge="end" onClick={() => onDelete(orderType.id)}>
                        <Delete />
                    </IconButton>
                </>
            }
        >
            <DragIndicator style={{ marginRight: '10px', color: '#888' }} />
            {isEditing ? (
                <TextField
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            ) : (
                <ListItemText primary={orderType.name} />
            )}
        </ListItem>
    );
};

const OrderTypeList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { orderTypes, loading, error } = useSelector((state: RootState) => state.orderType);

    const [newOrderTypeName, setNewOrderTypeName] = useState('');

    useEffect(() => {
        dispatch(fetchOrderedOrderTypes());
    }, [dispatch]);

    const handleAddOrderType = () => {
        if (newOrderTypeName.trim()) {
            dispatch(createOrderType({ name: newOrderTypeName }));
            setNewOrderTypeName('');
        }
    };

    const handleDeleteOrderType = (id: number) => {
        dispatch(deleteOrderType(id));
    };

    const handleSaveOrderType = (orderType: OrderType) => {
        dispatch(updateOrderType({ id: orderType.id, orderType: { name: orderType.name } }));
    };

    const moveOrderType = (dragIndex: number, hoverIndex: number) => {
        const reorderedOrderTypes = [...orderTypes];
        const [draggedOrderType] = reorderedOrderTypes.splice(dragIndex, 1);
        reorderedOrderTypes.splice(hoverIndex, 0, draggedOrderType);

        dispatch(setOrderTypesOrder(reorderedOrderTypes));

        const orderedIds = reorderedOrderTypes.map((orderType) => orderType.id);
        dispatch(reorderOrderTypes(orderedIds));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ maxWidth: 400, margin: '20px auto', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Order Types
                </Typography>
                <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <TextField
                        label="Order Type Name"
                        variant="outlined"
                        fullWidth
                        value={newOrderTypeName}
                        onChange={(e) => setNewOrderTypeName(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddOrderType}>
                        Add
                    </Button>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">Error: {error}</Typography>
                ) : (
                    <List>
                        {orderTypes.map((orderType, index) => (
                            <OrderTypeItem
                                key={orderType.id}
                                orderType={orderType}
                                index={index}
                                moveOrderType={moveOrderType}
                                onDelete={handleDeleteOrderType}
                                onSave={handleSaveOrderType}
                            />
                        ))}
                    </List>
                )}
            </Box>
        </DndProvider>
    );
};

export default OrderTypeList;
