import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosConfig';
import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

const initializeWebSocket = (access_token: string) => {
    socket = io('https://api.alcovrach.tech', {
        query: { token: access_token },
        transports: ['websocket'],
        withCredentials: true,
    });

    socket.on('connect', () => console.log('WebSocket connected for location tracking'));
    socket.on('disconnect', reason => {
        console.log('WebSocket disconnected:', reason);
        if (reason === 'io server disconnect') socket?.connect();
    });

    navigator.geolocation.watchPosition(
        ({ coords: { latitude, longitude } }) => socket?.emit('sendLocation', { latitude, longitude }),
        error => console.error('Error fetching location:', error),
        { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 },
    );
};

export const loginThunk = createAsyncThunk(
    'auth/login',
    async ({ email, password, rememberMe } : {email: string, password: string, rememberMe: boolean}) => {
        const response = await axiosInstance.post('/auth/login', { email, password, rememberMe });
        const { access_token, user } = response.data;

        if (rememberMe) localStorage.setItem('token', access_token);
        else sessionStorage.setItem('token', access_token);

        if (user.role === 'EXECUTOR_ADMINISTRATOR') initializeWebSocket(access_token);

        window.addEventListener('beforeunload', () => axiosInstance.post('/auth/logout', { token: access_token }));
        return { token: access_token, user };
    }
);

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
        await axiosInstance.post('/auth/logout', { token });
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        socket?.disconnect();
        socket = null;
    }
});
