// src/hooks/useKeepAlive.ts
import { useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';


export const useKeepAlive = (intervalMs: number) => {
    useEffect(() => {
        const keepAlive = () => {
            axiosInstance.post('/auth/keep-alive').catch(console.error);
        };

        const intervalId = setInterval(keepAlive, intervalMs);

        return () => clearInterval(intervalId);
    }, [intervalMs]);
};
