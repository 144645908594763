// src/components/ExecutorSkill/ExecutorSkillList.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchOrderedSkills, createExecutorSkill, updateExecutorSkill, deleteExecutorSkill, reorderExecutorSkills } from '../../redux/executorSkill/executorSkillThunks';
import { ExecutorSkill } from '../../redux/executorSkill/types';
import { setSkillsOrder } from '../../redux/executorSkill/executorSkillSlice';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Box,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Edit, Delete, Save, DragIndicator } from '@mui/icons-material';

const ITEM_TYPE = 'ExecutorSkill';

interface SkillItemProps {
    skill: ExecutorSkill;
    index: number;
    moveSkill: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (skill: ExecutorSkill) => void;
}

const SkillItem: React.FC<SkillItemProps> = ({ skill, index, moveSkill, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveSkill(item.index, index);
                item.index = index;
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(skill.name);

    const handleSave = () => {
        onSave({ ...skill, name: editedName });
        setIsEditing(false);
    };

    return (
        <ListItem
            ref={(node) => dragRef(dropRef(node))}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                borderBottom: '1px solid #ddd',
            }}
            secondaryAction={
                <>
                    {isEditing ? (
                        <IconButton edge="end" onClick={handleSave}>
                            <Save />
                        </IconButton>
                    ) : (
                        <IconButton edge="end" onClick={() => setIsEditing(true)}>
                            <Edit />
                        </IconButton>
                    )}
                    <IconButton edge="end" onClick={() => onDelete(skill.id)}>
                        <Delete />
                    </IconButton>
                </>
            }
        >
            <DragIndicator style={{ marginRight: '10px', color: '#888' }} />
            {isEditing ? (
                <TextField
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            ) : (
                <ListItemText primary={skill.name} />
            )}
        </ListItem>
    );
};

const ExecutorSkillList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { skills, loading, error } = useSelector((state: RootState) => state.executorSkill);

    const [newSkillName, setNewSkillName] = useState('');

    useEffect(() => {
        dispatch(fetchOrderedSkills());
    }, [dispatch]);

    const handleAddSkill = () => {
        if (newSkillName.trim()) {
            dispatch(createExecutorSkill({ name: newSkillName })); // Ensure only name is passed
            setNewSkillName('');
        }
    };

    const handleDeleteSkill = (id: number) => {
        dispatch(deleteExecutorSkill(id));
    };

    const handleSaveSkill = (skill: ExecutorSkill) => {
        dispatch(updateExecutorSkill({ id: skill.id, skill: { name: skill.name } })); // Pass both id and skill object with name
    };

    const moveSkill = (dragIndex: number, hoverIndex: number) => {
        const reorderedSkills = [...skills];
        const [draggedSkill] = reorderedSkills.splice(dragIndex, 1);
        reorderedSkills.splice(hoverIndex, 0, draggedSkill);

        // Update the local state optimistically
        dispatch(setSkillsOrder(reorderedSkills));

        // Send updated order to backend
        const orderedIds = reorderedSkills.map((skill) => skill.id);
        dispatch(reorderExecutorSkills(orderedIds));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ maxWidth: 400, margin: '20px auto', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Executor Skills
                </Typography>
                <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <TextField
                        label="Skill Name"
                        variant="outlined"
                        fullWidth
                        value={newSkillName}
                        onChange={(e) => setNewSkillName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddSkill}
                    >
                        Add
                    </Button>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">Error: {error}</Typography>
                ) : (
                    <List>
                        {skills.map((skill, index) => (
                            <SkillItem
                                key={skill.id}
                                skill={skill}
                                index={index}
                                moveSkill={moveSkill}
                                onDelete={handleDeleteSkill}
                                onSave={handleSaveSkill}
                            />
                        ))}
                    </List>
                )}
            </Box>
        </DndProvider>
    );
};

export default ExecutorSkillList;
