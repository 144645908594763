import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchOrderedAreas, createArea, updateArea, deleteArea, reorderAreas } from '../../redux/area/areaThunks';
import { Area } from '../../redux/area/areaTypes';
import { setAreasOrder } from '../../redux/area/areaSlice';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Box,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Edit, Delete, Save, DragIndicator } from '@mui/icons-material';

const ITEM_TYPE = 'Area';

interface AreaItemProps {
    area: Area;
    index: number;
    moveArea: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (id: number) => void;
    onSave: (area: Area) => void;
}

const AreaItem: React.FC<AreaItemProps> = ({ area, index, moveArea, onDelete, onSave }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: ITEM_TYPE,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveArea(item.index, index);
                item.index = index;
            }
        },
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(area.name);

    const handleSave = () => {
        onSave({ ...area, name: editedName });
        setIsEditing(false);
    };

    return (
        <ListItem
            ref={(node) => dragRef(dropRef(node))}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                borderBottom: '1px solid #ddd',
            }}
            secondaryAction={
                <>
                    {isEditing ? (
                        <IconButton edge="end" onClick={handleSave}>
                            <Save />
                        </IconButton>
                    ) : (
                        <IconButton edge="end" onClick={() => setIsEditing(true)}>
                            <Edit />
                        </IconButton>
                    )}
                    <IconButton edge="end" onClick={() => onDelete(area.id)}>
                        <Delete />
                    </IconButton>
                </>
            }
        >
            <DragIndicator style={{ marginRight: '10px', color: '#888' }} />
            {isEditing ? (
                <TextField
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            ) : (
                <ListItemText primary={area.name} />
            )}
        </ListItem>
    );
};

const AreaList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { areas, loading, error } = useSelector((state: RootState) => state.areas);

    const [newAreaName, setNewAreaName] = useState('');

    useEffect(() => {
        dispatch(fetchOrderedAreas());
    }, [dispatch]);

    const handleAddArea = () => {
        if (newAreaName.trim()) {
            dispatch(createArea({ name: newAreaName }));
            setNewAreaName('');
        }
    };

    const handleDeleteArea = (id: number) => {
        dispatch(deleteArea(id));
    };

    const handleSaveArea = (area: Area) => {
        dispatch(updateArea({ id: area.id, area: { name: area.name } }));
    };

    const moveArea = (dragIndex: number, hoverIndex: number) => {
        const reorderedAreas = [...areas];
        const [draggedArea] = reorderedAreas.splice(dragIndex, 1);
        reorderedAreas.splice(hoverIndex, 0, draggedArea);

        dispatch(setAreasOrder(reorderedAreas));
        const orderedIds = reorderedAreas.map((area) => area.id);
        dispatch(reorderAreas(orderedIds));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={{ maxWidth: 400, margin: '20px auto', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Areas
                </Typography>
                <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <TextField
                        label="Area Name"
                        variant="outlined"
                        fullWidth
                        value={newAreaName}
                        onChange={(e) => setNewAreaName(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddArea}>
                        Add
                    </Button>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">Error: {error}</Typography>
                ) : (
                    <List>
                        {areas.map((area, index) => (
                            <AreaItem
                                key={area.id}
                                area={area}
                                index={index}
                                moveArea={moveArea}
                                onDelete={handleDeleteArea}
                                onSave={handleSaveArea}
                            />
                        ))}
                    </List>
                )}
            </Box>
        </DndProvider>
    );
};

export default AreaList;
