import axiosInstance from './axiosConfig';

export interface ReportField {
    id: number;
    templateId: number;
    name: string;
    fieldType: string; // Replace with enum if needed
    required?: boolean;
    valueListId?: number;
    link: string;
}

// Fetch all report fields for a specific template
export const fetchReportFieldsByTemplate = async (templateId: number): Promise<ReportField[]> => {
    const response = await axiosInstance.get(`/report-fields/template/${templateId}`);
    return response.data;
};

// Create a new report field
export const createReportField = async (reportField: Omit<ReportField, 'id'>): Promise<ReportField> => {
    const response = await axiosInstance.post('/report-fields', reportField);
    return response.data;
};

// Update an existing report field by ID
export const updateReportField = async (id: number, reportField: Partial<ReportField>): Promise<ReportField> => {
    const response = await axiosInstance.put(`/report-fields/${id}`, reportField);
    return response.data;
};

// Delete a report field by ID
export const deleteReportField = async (id: number): Promise<void> => {
    await axiosInstance.delete(`/report-fields/${id}`);
};
