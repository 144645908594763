import React from 'react';
import ValueListList from '../components/ValueList/ValueListList';

export const ValueList = () => {
    return (
        <>
            <ValueListList />
        </>
    );
};
