import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    Box,
} from '@mui/material';
import { fetchValueLists } from '../../redux/valueList/valueListThunks';
import { RootState, AppDispatch } from '../../redux/store';

const ValueListList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { valueLists, loading, error } = useSelector(
        (state: RootState) => state.valueList
    );

    useEffect(() => {
        dispatch(fetchValueLists());
    }, [dispatch]);

    const handleRowClick = (id: number) => {
        navigate(`/value-lists/${id}`); // Navigate to edit mode for the selected Value List
    };

    const handleCreate = () => {
        navigate(`/value-lists/new`); // Navigate to create mode
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Value Lists
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCreate}>
                Add Value List
            </Button>

            {loading ? (
                <Typography>Loading...</Typography>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Values</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {valueLists.map((list) => (
                            <TableRow
                                key={list.id}
                                onClick={() => handleRowClick(list.id)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                            >
                                <TableCell>{list.name}</TableCell>
                                <TableCell>{list.options.join(', ')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </Box>
    );
};

export default ValueListList;
