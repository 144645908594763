import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ResponsiveAppBar from './components/AppBar/ResponsiveAppBar';
import Login from './pages/Login';
import { Teams } from './pages/Teams';
import Employees from './pages/Employees';
import ProtectedRoute from './routes/ProtectedRoute';
import WorkOrdersPage from './pages/WorkOrdersPage';
import WorkOrderForm from './components/WorkOrders/WorkOrderForm';
import UserForm from './components/Users/UserForm';
import TeamForm from './components/Teams/TeamForm';
import { Resolutions } from './pages/Resolutions';
import ResolutionForm from './components/Resolutions/ResolutionForm';
import { ExecutorSkill } from './pages/ExecutorSkill'; // Import ExecutorSkill page
import { useKeepAlive } from './hooks/useKeepAlive';
import { OrderType } from './pages/OrderType';
import Areas from './pages/Areas';
import { ValueList } from './pages/ValueList'; // Import ValueList page
import ValueListForm from './components/ValueList/ValueListForm';
import {Templates} from './pages/Templates';
import {TemplateForm} from "./components/Templates/TemplateForm"; // Import ValueListForm

const App: React.FC = () => {
    useKeepAlive(5 * 60 * 1000);

    return (
        <AuthProvider>
            <ResponsiveAppBar />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                    {/* Work Orders Routes */}
                    <Route path="/workorders" element={<WorkOrdersPage />} />
                    <Route path="/workorders/new" element={<WorkOrderForm />} />
                    <Route path="/workorders/:id" element={<WorkOrderForm />} />

                    {/* Teams Routes */}
                    <Route path="/teams" element={<Teams />} />
                    <Route path="/teams/new" element={<TeamForm />} />
                    <Route path="/teams/:id" element={<TeamForm />} />

                    {/* Resolutions Routes */}
                    <Route path="/resolutions" element={<Resolutions />} />
                    <Route path="/resolutions/new" element={<ResolutionForm />} />
                    <Route path="/resolutions/:id" element={<ResolutionForm />} />

                    {/* Employees Routes */}
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/users/new" element={<UserForm />} />
                    <Route path="/users/:id" element={<UserForm />} />

                    {/* Executor Skill Route */}
                    <Route path="/executor-skill" element={<ExecutorSkill />} /> {/* ExecutorSkill */}

                    {/* Order Type Route */}
                    <Route path="/order-types" element={<OrderType />} /> {/* OrderType */}

                    {/* Areas Route */}
                    <Route path="/areas" element={<Areas />} /> {/* Areas */}

                    {/* Value List Routes */}
                    <Route path="/value-lists" element={<ValueList />} /> {/* ValueList */}
                    <Route path="/value-lists/new" element={<ValueListForm />} /> {/* Create ValueList */}
                    <Route path="/value-lists/:id" element={<ValueListForm />} /> {/* Edit ValueList */}

                    {/* Template Routes */}
                    <Route path="/templates" element={<Templates />} />
                    <Route path="/templates/new" element={<TemplateForm />} />
                    <Route path="/templates/edit/:id" element={<TemplateForm />} />

                </Route>

                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </AuthProvider>
    );
};

export default App;
