import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    fetchTemplatesThunk,
    fetchTemplateByIdThunk,
    createTemplateThunk,
    updateTemplateThunk,
    deleteTemplateThunk,
} from "./templateThunks";
import { Template } from "./types";

interface TemplateState {
    templates: Template[];
    selectedTemplate: Template | null;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
}

const initialState: TemplateState = {
    templates: [],
    selectedTemplate: null,
    status: "idle",
    error: null,
};

const templatesSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {
        clearTemplateError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplatesThunk.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchTemplatesThunk.fulfilled, (state, action: PayloadAction<Template[]>) => {
                state.status = "succeeded";
                state.templates = action.payload;
                state.error = null;
            })
            .addCase(fetchTemplatesThunk.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to fetch templates";
            })
            .addCase(fetchTemplateByIdThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                state.selectedTemplate = action.payload;
                state.error = null;
            })
            .addCase(createTemplateThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                state.templates.push(action.payload);
                state.error = null;
            })
            .addCase(updateTemplateThunk.fulfilled, (state, action: PayloadAction<Template>) => {
                const index = state.templates.findIndex((template) => template.id === action.payload.id);
                if (index !== -1) state.templates[index] = action.payload;
                state.error = null;
            })
            .addCase(deleteTemplateThunk.fulfilled, (state, action: PayloadAction<number>) => {
                state.templates = state.templates.filter((template) => template.id !== action.payload);
                state.error = null;
            });
    },
});

export const { clearTemplateError } = templatesSlice.actions;
export default templatesSlice.reducer;
