import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchClients,
    fetchClientById,
    createClient,
    updateClient,
    deleteClient,
    reverseGeocode,
    geocodeAddress,
    searchClients,
} from '../../api/clientsApi';
import { CreateClientDto, UpdateClientDto, GeocodeAddressDto } from './types';

const handleGeocoding = async (address: {
    city?: string;
    street?: string;
    building?: string;
    latitude?: number;
    longitude?: number;
}) => {
    if (
        address &&
        !address.latitude &&
        !address.longitude &&
        address.city &&
        address.street &&
        address.building
    ) {
        const coordinates = await geocodeAddress({
            city: address.city,
            street: address.street,
            building: address.building,
        });
        address.latitude = coordinates.latitude;
        address.longitude = coordinates.longitude;
    }
};


// Fetch all clients
export const fetchClientsThunk = createAsyncThunk(
    'clients/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchClients();
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to fetch clients.');
        }
    }
);

// Search clients by name or phone
export const searchClientsThunk = createAsyncThunk(
    'clients/search',
    async (query: string, { rejectWithValue }) => {
        try {
            return await searchClients(query);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to search clients.');
        }
    }
);

// Fetch a client by ID
export const fetchClientByIdThunk = createAsyncThunk(
    'clients/fetchById',
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchClientById(id);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to fetch client by ID.');
        }
    }
);

// Create a new client
export const createClientThunk = createAsyncThunk(
    'clients/create',
    async (clientData: CreateClientDto, { rejectWithValue }) => {
        try {
            // Perform geocoding if necessary
            if (clientData.address) {
                await handleGeocoding(clientData.address);
            }
            return await createClient(clientData);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to create client.');
        }
    }
);

// Update an existing client
export const updateClientThunk = createAsyncThunk(
    'clients/update',
    async (
        { id, clientData }: { id: number; clientData: UpdateClientDto },
        { rejectWithValue }
    ) => {
        try {
            // Perform geocoding if necessary
            if (clientData.address) {
                await handleGeocoding(clientData.address);
            }
            return await updateClient(id, clientData);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to update client.');
        }
    }
);


// Delete a client
export const deleteClientThunk = createAsyncThunk(
    'clients/delete',
    async (id: number, { rejectWithValue }) => {
        try {
            return await deleteClient(id);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to delete client.');
        }
    }
);

// Reverse geocode coordinates to an address
export const reverseGeocodeThunk = createAsyncThunk(
    'clients/reverseGeocode',
    async ({ latitude, longitude }: { latitude: number; longitude: number }, { rejectWithValue }) => {
        try {
            return await reverseGeocode(latitude, longitude);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to reverse geocode coordinates.');
        }
    }
);

// Geocode an address to coordinates
export const geocodeAddressThunk = createAsyncThunk(
    'clients/geocodeAddress',
    async (addressData: GeocodeAddressDto, { rejectWithValue }) => {
        try {
            return await geocodeAddress(addressData);
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message || 'Failed to geocode address.');
        }
    }
);
