import axiosInstance from './axiosConfig';
import { CreateClientDto, UpdateClientDto, GeocodeAddressDto } from '../redux/clients/types';

export const fetchClients = async () => {
    const response = await axiosInstance.get('/clients');
    return response.data;
};

export const searchClients = async (query: string) => {
    if (!query) {
        throw new Error('Query parameter is required');
    }
    const response = await axiosInstance.get(`/clients/search?query=${query}`);
    return response.data;
};

export const fetchClientById = async (id: number) => {
    const response = await axiosInstance.get(`/clients/${id}`);
    return response.data;
};

export const createClient = async (clientData: CreateClientDto) => {
    const response = await axiosInstance.post('/clients', clientData);
    return response.data;
};

export const updateClient = async (id: number, clientData: UpdateClientDto) => {
    const response = await axiosInstance.put(`/clients/${id}`, clientData);
    return response.data;
};

export const deleteClient = async (id: number) => {
    const response = await axiosInstance.delete(`/clients/${id}`);
    return response.data;
};

export const reverseGeocode = async (latitude: number, longitude: number) => {
    const response = await axiosInstance.get(`/clients/geocode/address?latitude=${latitude}&longitude=${longitude}`);
    return response.data;
};

export const geocodeAddress = async (addressData: GeocodeAddressDto) => {
    const response = await axiosInstance.get(`/clients/geocode/address`, { params: addressData });
    return response.data;
};
