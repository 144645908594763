import React, { useState } from "react";
import {
    Button,
    TextField,
    MenuItem,
    IconButton,
    Typography,
    Select,
    InputLabel,
    FormControl,
    Box,
    Menu,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { dynamicFieldTypes } from "./fieldTypes";

// Align Field interface with TemplateFieldDto from the backend
export interface Field {
    id: string; // Unique identifier for front-end usage
    name: string;
    fieldType: string; // Matches the backend Enum 'FieldType'
    required?: boolean;
    placeholder?: string;
    minValue?: number;
    maxValue?: number;
    step?: number;
    valueListId?: number; // For SELECTOR type
    validation?: string;
}

interface DynamicFieldsProps {
    fields: Field[];
    onUpdate: (fields: Field[]) => void;
}

export const DynamicFields: React.FC<DynamicFieldsProps> = ({ fields, onUpdate }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleAddField = (fieldType: string) => {
        const newField: Field = {
            id: `${Date.now()}`,
            name: "",
            fieldType,
            required: false,
        };
        onUpdate([...fields, newField]);
        handleMenuClose();
    };

    const updateField = (id: string, updates: Partial<Field>) => {
        onUpdate(fields.map((field) => (field.id === id ? { ...field, ...updates } : field)));
    };

    const removeField = (id: string) => {
        onUpdate(fields.filter((field) => field.id !== id));
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Дополнительные поля
            </Typography>

            {fields.map((field) => (
                <Box key={field.id} display="flex" alignItems="center" gap={2} marginBottom={2}>
                    {/* Field Name Input */}
                    <TextField
                        label="Название поля"
                        value={field.name}
                        onChange={(e) => updateField(field.id, { name: e.target.value })}
                        size="small"
                        fullWidth
                    />

                    {/* Field Type Selector */}
                    <FormControl size="small" fullWidth>
                        <InputLabel>Тип поля</InputLabel>
                        <Select
                            value={field.fieldType}
                            onChange={(e) => updateField(field.id, { fieldType: e.target.value })}
                        >
                            {dynamicFieldTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Additional Inputs Based on Field Type */}
                    {field.fieldType === "SELECTOR" && (
                        <TextField
                            label="ID списка значений"
                            value={field.valueListId || ""}
                            onChange={(e) => updateField(field.id, { valueListId: Number(e.target.value) })}
                            size="small"
                            placeholder="Введите ID списка"
                            fullWidth
                        />
                    )}

                    {field.fieldType === "BUTTON" && (
                        <TextField
                            label="Ссылка кнопки"
                            value={field.placeholder || ""}
                            onChange={(e) => updateField(field.id, { placeholder: e.target.value })}
                            size="small"
                            placeholder="Введите URL"
                            fullWidth
                        />
                    )}

                    {field.fieldType === "INTEGER" && (
                        <Box display="flex" gap={1}>
                            <TextField
                                label="Min"
                                type="number"
                                value={field.minValue || ""}
                                onChange={(e) => updateField(field.id, { minValue: Number(e.target.value) })}
                                size="small"
                            />
                            <TextField
                                label="Max"
                                type="number"
                                value={field.maxValue || ""}
                                onChange={(e) => updateField(field.id, { maxValue: Number(e.target.value) })}
                                size="small"
                            />
                            <TextField
                                label="Step"
                                type="number"
                                value={field.step || ""}
                                onChange={(e) => updateField(field.id, { step: Number(e.target.value) })}
                                size="small"
                            />
                        </Box>
                    )}

                    {/* Delete Field Button */}
                    <IconButton color="error" onClick={() => removeField(field.id)}>
                        <Delete />
                    </IconButton>
                </Box>
            ))}

            {/* Add New Field Dropdown */}
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleMenuOpen}>
                Добавить поле
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {dynamicFieldTypes.map((type) => (
                    <MenuItem key={type.value} onClick={() => handleAddField(type.value)}>
                        {type.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
