import React from 'react';
import OrderTypeList from '../components/OrderType/OrderTypeList';

export const OrderType = () => {
    return (
        <>
            <OrderTypeList/>
        </>
    );
};
