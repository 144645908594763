import axiosInstance from './axiosConfig';
import { Area } from '../redux/area/areaTypes';

export const fetchAreasApi = async () => {
    const response = await axiosInstance.get<Area[]>('/areas');
    return response.data;
};

export const reorderAreasApi = async (orderedIds: number[]) => {
    const response = await axiosInstance.patch('/areas/reorder', { orderedIds });
    return response.data;
};

export const createAreaApi = async (area: { name: string }) => {
    const response = await axiosInstance.post('/areas', { name: area.name });
    return response.data;
};

export const updateAreaApi = async (id: number, area: Partial<Area>) => {
    const response = await axiosInstance.patch(`/areas/${id}`, area);
    return response.data;
};

export const deleteAreaApi = async (id: number) => {
    const response = await axiosInstance.delete(`/areas/${id}`);
    return response.data;
};
