import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    Typography,
    Box,
    CircularProgress,
    InputLabel,
    FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../redux/store";
import {
    fetchTemplateByIdThunk,
    createTemplateThunk,
    updateTemplateThunk,
} from "../../redux/templates/templateThunks";
import { fetchOrderedOrderTypes } from "../../redux/orderType/orderTypeThunks";
import { fetchOrderedSkills } from "../../redux/executorSkill/executorSkillThunks";
import { fetchOrderedAreas } from "../../redux/area/areaThunks";
import { fetchUsers } from "../../redux/user/userThunks";

import { Template } from "../../redux/templates/types";
import { DynamicFields } from "./DynamicFields";
import { ReportFields } from "./ReportFields";
import { Resolutions } from "./Resolutions";

export const TemplateForm: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { selectedTemplate } = useSelector((state: RootState) => state.template);
    const { orderTypes, loading: orderTypeLoading } = useSelector((state: RootState) => state.orderType);
    const { skills, loading: skillLoading } = useSelector((state: RootState) => state.executorSkill);
    const { areas, loading: areaLoading } = useSelector((state: RootState) => state.areas);
    const { users, status: userStatus } = useSelector((state: RootState) => state.users);

    const userLoading = userStatus === "loading";

    // Template local state
    const [template, setTemplate] = useState<Template>({
        id: 0,
        name: "",
        orderTypeId: 0,
        description: "",
        duration: 0,
        executorSkillsId: 0,
        areaId: 0,
        executorId: 0,
        additionalFields: [],
        reportFields: [],
        successfulResolutions: [],
        unsuccessfulResolutions: [],
    });

    const isEditMode = Boolean(id);

    // Fetch initial data
    useEffect(() => {
        if (isEditMode) dispatch(fetchTemplateByIdThunk(Number(id)));
        dispatch(fetchOrderedOrderTypes());
        dispatch(fetchOrderedSkills());
        dispatch(fetchOrderedAreas());
        dispatch(fetchUsers());
    }, [dispatch, id, isEditMode]);

    useEffect(() => {
        if (isEditMode && selectedTemplate) {
            setTemplate({
                ...selectedTemplate,
                duration: selectedTemplate.duration || 0,
            });
        }
    }, [isEditMode, selectedTemplate]);

    const handleSubmit = () => {
        if (isEditMode) {
            dispatch(updateTemplateThunk({ id: Number(id), template }));
        } else {
            dispatch(createTemplateThunk(template));
        }
        navigate("/templates");
    };

    const handleChange = (key: keyof Template, value: any) => {
        setTemplate((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                {isEditMode ? "Редактировать шаблон" : "Создать новый шаблон"}
            </Typography>

            <Box marginBottom={2}>
                {/* Name */}
                <TextField
                    label="Название шаблона *"
                    value={template.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    required
                    fullWidth
                    margin="normal"
                />

                {/* Order Type */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Тип наряда</InputLabel>
                    {orderTypeLoading ? (
                        <CircularProgress />
                    ) : (
                        <Select
                            value={template.orderTypeId || ""}
                            onChange={(e) => handleChange("orderTypeId", Number(e.target.value))}
                        >
                            <MenuItem value="" disabled>
                                - Без типа -
                            </MenuItem>
                            {orderTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                {/* Description */}
                <TextField
                    label="Описание"
                    value={template.description}
                    onChange={(e) => handleChange("description", e.target.value)}
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                />

                {/* Duration */}
                <Box display="flex" gap={2} marginBottom={2}>
                    <TextField
                        label="Часы"
                        type="number"
                        value={Math.floor((template.duration ?? 0) / 60)} // Use `??` for nullish coalescing
                        onChange={(e) =>
                            handleChange(
                                "duration",
                                Number(e.target.value) * 60 + ((template.duration ?? 0) % 60) // Ensure fallback
                            )
                        }
                    />
                    <TextField
                        label="Минуты"
                        type="number"
                        value={(template.duration ?? 0) % 60} // Use `??` for fallback
                        onChange={(e) =>
                            handleChange(
                                "duration",
                                Math.floor((template.duration ?? 0) / 60) * 60 + Number(e.target.value) // Ensure fallback
                            )
                        }
                    />
                </Box>


                {/* Executor Skills */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Навыки исполнителя</InputLabel>
                    {skillLoading ? (
                        <CircularProgress />
                    ) : (
                        <Select
                            value={template.executorSkillsId || ""}
                            onChange={(e) => handleChange("executorSkillsId", Number(e.target.value))}
                        >
                            <MenuItem value="" disabled>
                                - Не указано -
                            </MenuItem>
                            {skills.map((skill) => (
                                <MenuItem key={skill.id} value={skill.id}>
                                    {skill.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                {/* Area */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Участок</InputLabel>
                    {areaLoading ? (
                        <CircularProgress />
                    ) : (
                        <Select
                            value={template.areaId || ""}
                            onChange={(e) => handleChange("areaId", Number(e.target.value))}
                        >
                            <MenuItem value="" disabled>
                                - Не указано -
                            </MenuItem>
                            {areas.map((area) => (
                                <MenuItem key={area.id} value={area.id}>
                                    {area.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                {/* Executor */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Исполнитель</InputLabel>
                    {userLoading ? (
                        <CircularProgress />
                    ) : (
                        <Select
                            value={template.executorId || ""}
                            onChange={(e) => handleChange("executorId", Number(e.target.value))}
                        >
                            <MenuItem value="" disabled>
                                - Выберите исполнителя -
                            </MenuItem>
                            {users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.fullName}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>
            </Box>

            <DynamicFields
                fields={template.additionalFields.map((field) => ({
                    ...field,
                    id: String(field.id), // Convert `id` to string
                }))}
                onUpdate={(fields) =>
                    handleChange(
                        "additionalFields",
                        fields.map((field) => ({
                            ...field,
                            id: Number(field.id), // Convert back to number for saving
                        }))
                    )
                }
            />

            <ReportFields
                fields={template.reportFields}
                onAddField={(field) =>
                    setTemplate((prev) => ({ ...prev, reportFields: [...prev.reportFields, field] }))
                }
                onRemoveField={(id) =>
                    setTemplate((prev) => ({
                        ...prev,
                        reportFields: prev.reportFields.filter((field) => field.id !== id),
                    }))
                }
                onUpdateField={(id, updatedField) =>
                    setTemplate((prev) => ({
                        ...prev,
                        reportFields: prev.reportFields.map((field) =>
                            field.id === id ? { ...field, ...updatedField } : field
                        ),
                    }))
                }
            />

            <Resolutions
                templateId={template.id}
                selectedResolutions={[]}
                onUpdateResolutions={() => {}}
            />

            <Box marginTop={3}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {isEditMode ? "Сохранить изменения" : "Создать"}
                </Button>
            </Box>
        </div>
    );
};

export default TemplateForm;
