import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    IconButton,
    List,
    ListItem,
    Divider,
} from "@mui/material";
import { Close, Add } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
    fetchResolutionsThunk,
    linkResolutionsToTemplateThunk,
    unlinkResolutionsFromTemplateThunk,
} from "../../redux/resolutions/resolutionsThunks";

interface Resolution {
    id: number;
    name: string;
    type: "SUCCESSFUL" | "UNSUCCESSFUL";
}

interface ResolutionsProps {
    templateId: number;
    selectedResolutions: Resolution[];
    onUpdateResolutions: (resolutions: Resolution[]) => void;
}

export const Resolutions: React.FC<ResolutionsProps> = ({
                                                            templateId,
                                                            selectedResolutions,
                                                            onUpdateResolutions,
                                                        }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { resolutions, status } = useSelector(
        (state: RootState) => state.resolutions
    );
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selected, setSelected] = useState<number[]>([]);

    // Fetch resolutions if not already loaded
    useEffect(() => {
        if (status === "idle") {
            dispatch(fetchResolutionsThunk());
        }
    }, [dispatch, status]);

    // Toggle dropdown visibility
    const handleAddClick = () => {
        setDropdownVisible((prev) => !prev);
    };

    // Handle checkbox selection for resolutions
    const handleResolutionCheck = (id: number) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((resId) => resId !== id));
        } else {
            setSelected([...selected, id]);
        }
    };

    // Add selected resolutions to the template
    const handleAddResolutions = () => {
        dispatch(linkResolutionsToTemplateThunk({ templateId, resolutionIds: selected }))
            .unwrap()
            .then(() => {
                const newResolutions = resolutions.filter((res) =>
                    selected.includes(res.id)
                );
                onUpdateResolutions([...selectedResolutions, ...newResolutions]); // Merge resolutions
                setSelected([]);
                setDropdownVisible(false);
            })
            .catch((err) => {
                console.error("Failed to add resolutions:", err);
            });
    };

    // Remove resolution from the template
    const handleRemoveResolution = (id: number) => {
        dispatch(unlinkResolutionsFromTemplateThunk({ templateId, resolutionIds: [id] }))
            .unwrap()
            .then(() => {
                onUpdateResolutions(
                    selectedResolutions.filter((res) => res.id !== id)
                );
            })
            .catch((err) => {
                console.error("Failed to remove resolution:", err);
            });
    };

    const successfulResolutions = resolutions.filter(
        (res) => res.type === "SUCCESSFUL"
    );
    const unsuccessfulResolutions = resolutions.filter(
        (res) => res.type === "UNSUCCESSFUL"
    );

    return (
        <Box>
            <Typography variant="h6">Возможные резолюции</Typography>
            <Box mt={2}>
                {/* Display Successful Resolutions */}
                <Typography variant="subtitle1">Успешные</Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                    {selectedResolutions
                        .filter((res) => res.type === "SUCCESSFUL")
                        .map((res) => (
                            <span
                                key={res.id}
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    backgroundColor: "#d4edda",
                                    color: "#155724",
                                    borderRadius: "4px",
                                    padding: "0.3rem 0.5rem",
                                }}
                            >
                                {res.name}
                                <IconButton
                                    size="small"
                                    onClick={() => handleRemoveResolution(res.id)}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </span>
                        ))}
                </Box>

                {/* Display Unsuccessful Resolutions */}
                <Typography variant="subtitle1" mt={2}>
                    Неуспешные
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                    {selectedResolutions
                        .filter((res) => res.type === "UNSUCCESSFUL")
                        .map((res) => (
                            <span
                                key={res.id}
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    backgroundColor: "#f8d7da",
                                    color: "#721c24",
                                    borderRadius: "4px",
                                    padding: "0.3rem 0.5rem",
                                }}
                            >
                                {res.name}
                                <IconButton
                                    size="small"
                                    onClick={() => handleRemoveResolution(res.id)}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </span>
                        ))}
                </Box>
            </Box>

            {/* Dropdown Button */}
            <Button
                variant="contained"
                startIcon={<Add />}
                style={{ marginTop: "1rem" }}
                onClick={handleAddClick}
            >
                Добавить резолюцию
            </Button>

            {/* Dropdown Menu */}
            {dropdownVisible && (
                <Box
                    mt={2}
                    style={{
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        padding: "1rem",
                        position: "absolute",
                        zIndex: 100,
                    }}
                >
                    {/* Successful Resolutions List */}
                    <Typography variant="subtitle1">Успешные резолюции</Typography>
                    <List>
                        {successfulResolutions.map((res) => (
                            <ListItem key={res.id}>
                                <Checkbox
                                    checked={selected.includes(res.id)}
                                    onChange={() => handleResolutionCheck(res.id)}
                                />
                                {res.name}
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    {/* Unsuccessful Resolutions List */}
                    <Typography variant="subtitle1" mt={2}>
                        Неуспешные резолюции
                    </Typography>
                    <List>
                        {unsuccessfulResolutions.map((res) => (
                            <ListItem key={res.id}>
                                <Checkbox
                                    checked={selected.includes(res.id)}
                                    onChange={() => handleResolutionCheck(res.id)}
                                />
                                {res.name}
                            </ListItem>
                        ))}
                    </List>
                    {/* Add Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "1rem" }}
                        onClick={handleAddResolutions}
                    >
                        Добавить
                    </Button>
                </Box>
            )}
        </Box>
    );
};
