import axiosInstance from './axiosConfig';

// src/api/resolutionsApi.ts
export interface Resolution {
    id: number;
    name: string;
    requiresNote: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
}

export interface ResolutionInput {
    name: string;
    requiresNote: boolean;
    type: 'SUCCESSFUL' | 'UNSUCCESSFUL';
}


// Fetch resolutions linked to a specific template
export const fetchResolutionsByTemplate = async (templateId: number): Promise<Resolution[]> => {
    const response = await axiosInstance.get(`/resolutions/template/${templateId}`);
    return response.data;
};

// Link resolutions to a template
export const linkResolutionsToTemplate = async (templateId: number, resolutionIds: number[]): Promise<void> => {
    await axiosInstance.post(`/resolutions/${templateId}/link`, resolutionIds);
};

// Unlink resolutions from a template
export const unlinkResolutionsFromTemplate = async (templateId: number, resolutionIds: number[]): Promise<void> => {
    await axiosInstance.delete(`/resolutions/${templateId}/unlink`, { data: resolutionIds });
};


// Get all resolutions
export const fetchResolutions = async () => {
    const response = await axiosInstance.get('/resolutions');
    return response.data.map((res: any) => ({
        ...res,
        noteRequired: res.requiresNote ?? false, // Map requiresNote to noteRequired, default to false if needed
    }));
};

// Get a single resolution by ID
export const fetchResolutionById = async (id: number) => {
    const response = await axiosInstance.get(`/resolutions/${id}`);
    return response.data;
};

// Convert `type` to uppercase only in the API call
export const createResolution = async (data: ResolutionInput): Promise<Resolution> => {
    const response = await axiosInstance.post('/resolutions', {
        ...data,
        type: data.type.toUpperCase(), // Ensure type is uppercase for backend compatibility
    });
    return response.data; // The API should return the complete resolution object
};

export const updateResolution = async (
    id: number,
    data: ResolutionInput
): Promise<Resolution> => {
    const response = await axiosInstance.patch(`/resolutions/${id}`, {
        ...data,
        type: data.type.toUpperCase(),
    });
    return response.data; // The API should return the updated resolution object
};


// Delete a resolution by ID
export const deleteResolution = async (id: number) => {
    const response = await axiosInstance.delete(`/resolutions/${id}`);
    return response.data;
};
