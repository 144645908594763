import React, { useState } from "react";
import {
    TextField,
    MenuItem,
    Button,
    IconButton,
    Typography,
    Select,
    InputLabel,
    FormControl,
    Box,
    Menu,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { reportFieldTypes } from "./fieldTypes";
import { ReportFieldType } from "../../redux/templates/types";

export interface ReportField {
    id: number;
    name: string;
    fieldType: ReportFieldType;
    required?: boolean;
    valueListId?: number;
    link?: string;
}

interface ReportFieldsProps {
    fields: ReportField[];
    onAddField: (field: ReportField) => void;
    onRemoveField: (id: number) => void;
    onUpdateField: (id: number, updates: Partial<ReportField>) => void;
}

export const ReportFields: React.FC<ReportFieldsProps> = ({ fields, onAddField, onRemoveField, onUpdateField }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleAddField = (fieldType: string) => {
        const newField: ReportField = {
            id: Date.now(),
            name: "",
            fieldType: fieldType as ReportFieldType, // Cast string to ReportFieldType
            required: false,
            link: fieldType === "BUTTON" ? "" : undefined,
            valueListId: fieldType === "SELECTOR" ? undefined : undefined,
        };
        onAddField(newField);
        handleMenuClose();
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Поля отчета исполнителя
            </Typography>

            {fields.map((field) => (
                <Box key={field.id} display="flex" alignItems="center" gap={2} marginBottom={2}>
                    {/* Field Name Input */}
                    <TextField
                        label="Название поля"
                        value={field.name}
                        onChange={(e) => onUpdateField(field.id, { name: e.target.value })}
                        size="small"
                        fullWidth
                    />

                    {/* Field Type Selector */}
                    <FormControl fullWidth margin="dense" size="small">
                        <InputLabel>Тип поля</InputLabel>
                        <Select
                            value={field.fieldType}
                            onChange={(e) => onUpdateField(field.id, { fieldType: e.target.value as ReportFieldType })}
                        >
                            {reportFieldTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Button Field Input */}
                    {field.fieldType === "BUTTON" && (
                        <TextField
                            label="Ссылка кнопки"
                            value={field.link || ""}
                            onChange={(e) => onUpdateField(field.id, { link: e.target.value })}
                            size="small"
                            fullWidth
                        />
                    )}

                    {/* Remove Field Button */}
                    <IconButton color="error" onClick={() => onRemoveField(field.id)}>
                        <Delete />
                    </IconButton>
                </Box>
            ))}

            {/* Add New Field Dropdown */}
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleMenuOpen}>
                Добавить поле отчета
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {reportFieldTypes.map((type) => (
                    <MenuItem key={type.value} onClick={() => handleAddField(type.value)}>
                        {type.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
