import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    fetchTemplatesApi,
    fetchTemplateById,
    createTemplate,
    updateTemplate,
    deleteTemplate,
} from "../../api/templatesApi";
import { Template } from "./types";

// Fetch all templates
export const fetchTemplatesThunk = createAsyncThunk(
    "templates/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            return await fetchTemplatesApi();
        } catch (error) {
            return rejectWithValue("Failed to fetch templates");
        }
    }
);

// Fetch a single template by ID
export const fetchTemplateByIdThunk = createAsyncThunk(
    "templates/fetchById",
    async (id: number, { rejectWithValue }) => {
        try {
            return await fetchTemplateById(id);
        } catch (error) {
            return rejectWithValue(`Failed to fetch template with ID ${id}`);
        }
    }
);

// Create a new template
export const createTemplateThunk = createAsyncThunk(
    "templates/create",
    async (template: Omit<Template, "id">, { rejectWithValue }) => {
        try {
            return await createTemplate(template);
        } catch (error) {
            return rejectWithValue("Failed to create template");
        }
    }
);

// Update an existing template
export const updateTemplateThunk = createAsyncThunk(
    "templates/update",
    async ({ id, template }: { id: number; template: Partial<Omit<Template, "id">> }, { rejectWithValue }) => {
        try {
            return await updateTemplate(id, template);
        } catch (error) {
            return rejectWithValue(`Failed to update template with ID ${id}`);
        }
    }
);

// Delete a template by ID
export const deleteTemplateThunk = createAsyncThunk(
    "templates/delete",
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteTemplate(id);
            return id;
        } catch (error) {
            return rejectWithValue(`Failed to delete template with ID ${id}`);
        }
    }
);
