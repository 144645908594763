import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOrderedAreas, createArea, updateArea, deleteArea, reorderAreas } from './areaThunks';
import { Area } from './areaTypes'; // Import from types.ts

interface AreaState {
    areas: Area[];
    loading: boolean;
    error: string | null;
}

const initialState: AreaState = {
    areas: [],
    loading: false,
    error: null,
};

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        setAreasOrder(state, action: PayloadAction<Area[]>) {
            state.areas = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderedAreas.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderedAreas.fulfilled, (state, action: PayloadAction<Area[]>) => {
                state.loading = false;
                state.areas = action.payload;
            })
            .addCase(fetchOrderedAreas.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(createArea.fulfilled, (state, action: PayloadAction<Area>) => {
                state.areas.push(action.payload);
            })
            .addCase(updateArea.fulfilled, (state, action: PayloadAction<Area>) => {
                const index = state.areas.findIndex((area) => area.id === action.payload.id);
                if (index !== -1) state.areas[index] = action.payload;
            })
            .addCase(deleteArea.fulfilled, (state, action: PayloadAction<number>) => {
                state.areas = state.areas.filter((area) => area.id !== action.payload);
            });
    },
});

export const { setAreasOrder } = areaSlice.actions;
export default areaSlice.reducer;
