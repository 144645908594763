// Field types for DynamicFields
export const dynamicFieldTypes = [
    { value: "TEXT", label: "Текст" },
    { value: "FILE", label: "Файл" },
    { value: "LINK", label: "Ссылка" },
    { value: "SELECTOR", label: "Значение из списка" },
    { value: "MONEY", label: "Деньги" },
    { value: "INTEGER", label: "Число" },
    { value: "DATE", label: "Дата" },
    { value: "TIME", label: "Время" },
    { value: "DATE_TIME", label: "Дата и время" },
    { value: "BUTTON", label: "Кнопка" },
];

// Field types for ReportFields
export const reportFieldTypes = [
    { value: "TEXT", label: "Текст" },
    { value: "LINK", label: "Ссылка" },
    { value: "FILE", label: "Файл" },
    { value: "PHOTO", label: "Фото" },
    { value: "QR_CODE", label: "QR-код" },
    { value: "SIGNATURE", label: "Подпись" },
    { value: "SELECTOR", label: "Список значений" },
    { value: "MONEY", label: "Деньги" },
    { value: "INTEGER", label: "Целое число" },
    { value: "FLOAT", label: "Дробное число" },
    { value: "DATE", label: "Дата" },
    { value: "TIME", label: "Время" },
    { value: "DATE_TIME", label: "Дата и время" },
    { value: "BUTTON", label: "Кнопка" },
    { value: "YES_NO", label: "Да/Нет" },
    { value: "ACTION", label: "Действие" },
];
