// src/api/executorSkillApi.ts
import axiosInstance from './axiosConfig';
import { ExecutorSkill } from '../redux/executorSkill/types'; // Import from types.ts

export const fetchExecutorSkills = async () => {
    const response = await axiosInstance.get<ExecutorSkill[]>('/executor-skill');
    return response.data;
};

export const reorderExecutorSkillsApi = async (orderedIds: number[]) => {
    const response = await axiosInstance.patch('/executor-skill/reorder', { orderedIds });
    return response.data;
};

export const createExecutorSkillApi = async (skill: { name: string }) => {
    const response = await axiosInstance.post('/executor-skill', { name: skill.name });
    return response.data;
};

export const updateExecutorSkillApi = async (id: number, skill: Partial<ExecutorSkill>) => {
    const response = await axiosInstance.patch(`/executor-skill/${id}`, skill);
    return response.data;
};

export const deleteExecutorSkillApi = async (id: number) => {
    const response = await axiosInstance.delete(`/executor-skill/${id}`);
    return response.data;
};
